<template>
  <div>
    <vx-card :title="titleBank">
      <vs-table :data="bankData">

        <template slot="thead">
          <vs-th>ID</vs-th>
          <vs-th>Name</vs-th>
          <vs-th>Number</vs-th>
          <vs-th>Phone</vs-th>
          <vs-th></vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">

            <vs-td :data="tr.bank_id">
              {{ tr.bank_id }}
            </vs-td>

            <vs-td :data="tr.bank_fullname">
              {{ tr.bank_fullname }}
            </vs-td>

            <vs-td :data="tr.bank_number">
              {{ tr.bank_number }}
            </vs-td>

            <vs-td :data="tr.bank_phone">
              {{ tr.bank_phone }}
            </vs-td>

            <vs-td :data="tr.bank_number">
              <router-link :to="`/checkbankwithdraw/transactions/${bank}/${tr.bank_id}`">
                <vs-button type="border" size="small" icon-pack="feather" icon="icon-list" class="mr-2"
                           v-model="tr.bank_id">รายการเดินบัญชี
                </vs-button>
              </router-link>
            </vs-td>

          </vs-tr>
        </template>
      </vs-table>
    </vx-card>

  </div>
</template>

<script>
import axios from '../../axios'

export default {
  props: {
    bankName: String
  },
  data () {
    return {
      titleBank: `ตรวจสอบบัญชีถอน BANK ${this.bankName}`,
      bankData: [],
      bank: this.bankName,
      bankID: '',
      typename: ['บัญชีหลัก', 'บัญชีสำรอง'],
      bankRUN: ['ปิด', 'เปิด'],
      bankSHOW: ['ปิด', 'เปิด'],
      // File uploader
      uploadPopupSync: false,
      currentUploadStatement: {
        id: ''
      },
      error: null,
      confirmBankNumber: '',
      // Dropzone
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        paramName: 'file',
        autoProcessQueue: 'false',
        autoQueue: 'false',
        thumbnailWidth: 150,
        maxFilesize: 2,
        maxFiles: 1,
        acceptedFiles: '.html',
        createImageThumbnails: false,
        dictDefaultMessage: 'ลากไฟล์ หรือกดคลิกเพื่ออัพโหลด'
      }
    }
  },
  computed: {
    uploadStatementValidation () {
      return (this.currentUploadStatement.bank_number === this.confirmBankNumber)
    }
  },
  async mounted () {
    await axios
      .get(`bankwithdraw/list/account/${this.bank}`)
      .then(response => (this.bankData = response.data))

    if (this.bankData.status) {
      this.bankData = this.bankData.data
    } else {
      this.bankData = []
    }
  }
}

</script>

<!-- SASS styling -->
<style lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightgray;
  color: dimgray;
  padding: 10px 10px;
  min-height: 100px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: aliceblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
