<template>
  <div>
    <vx-card :title="titlePayment">
      <vs-table :data="paymentData">

        <template slot="thead">
          <vs-th>ID</vs-th>
          <vs-th>Name</vs-th>
          <vs-th>Number</vs-th>
          <vs-th>Phone</vs-th>
          <vs-th></vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">

            <vs-td :data="tr.payment_id">
              {{ tr.payment_id }}
            </vs-td>

            <vs-td :data="tr.payment_fullname">
              {{ tr.payment_fullname }}
            </vs-td>

            <vs-td :data="tr.payment_number">
              {{ tr.payment_number }}
            </vs-td>

            <vs-td :data="tr.payment_phone">
              {{ tr.payment_phone }}
            </vs-td>

            <vs-td :data="tr.payment_number">
              <router-link :to="`/checkpaymentwithdraw/transactions/${payment}/${tr.payment_id}`">
                <vs-button type="border" size="small" icon-pack="feather" icon="icon-list" class="mr-2"
                           v-model="tr.payment_id">รายการเดินบัญชี
                </vs-button>
              </router-link>
            </vs-td>

          </vs-tr>
        </template>
      </vs-table>
    </vx-card>

  </div>
</template>

<script>
import axios from '../../axios'

export default {
  props: {
    paymentName: String
  },
  data () {
    return {
      titlePayment: `ตรวจสอบบัญชีถอน payment ${this.paymentName}`,
      paymentData: [],
      payment: this.paymentName,
      paymentID: '',
      typename: ['บัญชีหลัก', 'บัญชีสำรอง'],
      paymentRUN: ['ปิด', 'เปิด'],
      paymentSHOW: ['ปิด', 'เปิด'],
      // File uploader
      uploadPopupSync: false,
      currentUploadStatement: {
        id: ''
      },
      error: null,
      confirmpaymentNumber: '',
      // Dropzone
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        paramName: 'file',
        autoProcessQueue: 'false',
        autoQueue: 'false',
        thumbnailWidth: 150,
        maxFilesize: 2,
        maxFiles: 1,
        acceptedFiles: '.html',
        createImageThumbnails: false,
        dictDefaultMessage: 'ลากไฟล์ หรือกดคลิกเพื่ออัพโหลด'
      }
    }
  },
  computed: {
    uploadStatementValidation () {
      return (this.currentUploadStatement.payment_number === this.confirmpaymentNumber)
    }
  },
  async mounted () {
    await axios
      .get(`paymentwithdraw/list/account/${this.payment}`)
      .then(response => (this.paymentData = response.data))

    if (this.paymentData.status) {
      this.paymentData = this.paymentData.data
    } else {
      this.paymentData = []
    }
  }
}

</script>

<!-- SASS styling -->
<style lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightgray;
  color: dimgray;
  padding: 10px 10px;
  min-height: 100px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: aliceblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
